<template>
  <b-overlay :show="is_busy" rounded="sm">
    <div class="container-fluid">
      <section class="card p-2">    
        <div class="card-body" id="printMe">
          <div class="text-center">
            <img :src="school.logo" class="center img-fluid" style="height:100px; width:400px" v-if="school.logo">
            <p>
              <span style="font-size: 20px; font-weight: bold">{{ school.school_name }}</span><br> 
              {{ school.slogan }}<br>
              {{ school.address }}<br>
            </p>
          </div>

          <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-3 col-12"></div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="" style="text-align:center">
                    <span v-if="profile.image">
                      <img class="img-fluid img-responsive thumbnail" :src="profile.image" alt="Profile picture" style="height:100px; display: block; margin-left: auto; margin-right: auto; width: 50%; border-radius:10px">
                    </span>

                    <h4 class="text-center mt-2">{{ this.profile.lastname }} {{ profile.firstname }} {{ profile.middlename }}</h4>
                  </div>
              </div> 

              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                      <tr>
                        <th>Student ID</th>
                        <td>{{ profile.roll }}</td>
                      </tr>
                      
                      <tr>
                        <th>Date of Birth</th>
                        <td>{{ profile.dob | myDate }}</td>
                      </tr>

                      <tr>
                        <th>Gender</th>
                        <td>{{ profile.gender | capitalize }}</td>
                      </tr>

                      <tr>
                        <th>Current Address</th>
                        <td>{{ profile.current_address }}</td>
                      </tr>

                      <tr>
                        <th>Parmanent Address</th>
                        <td>{{ profile.parmanent_address }}</td>
                      </tr>

                      <tr>
                        <th>Nationality (State of Origin)</th>
                        <td>{{ profile.country }} ({{ profile.get_state }})</td>
                      </tr>

                      <tr>
                        <th>LGA</th>
                        <td>{{ profile.get_city }}</td>
                      </tr>

                      <tr>
                        <th>Religion</th>
                        <td>{{ profile.religion | capitalize }}</td>
                      </tr>                     
                    </table>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="table-responsive">
                      <table class="table table-bordered table-hover">
                        <tr>
                          <th>Date of Admission</th>
                          <td>{{ profile.doa | myDate }}</td>
                        </tr>

                        <tr>
                          <th>Current Class</th>
                          <td>{{ profile.class }}</td>
                        </tr>

                        <tr>
                          <th>Medical Certificate</th>
                          <td><img class="img-fluid img-responsive" :src="profile.medical_certificate" style="height:60px; display: block; margin-left: auto; margin-right: auto; width: 50%; border-radius:10px" v-if="profile.medical_certificate"></td>
                        </tr>

                        <tr>
                          <th>Birth Certificate</th>
                          <td><img class="img-fluid img-responsive" :src="profile.birth_certificate" style="height:60px; display: block; margin-left: auto; margin-right: auto; width: 50%; border-radius:10px" v-if="profile.birth_certificate"></td>
                        </tr>

                        <tr>
                          <th>LGA ID</th>
                          <td><img class="img-fluid img-responsive" :src="profile.lgi" style="height:60px; display: block; margin-left: auto; margin-right: auto; width: 50%; border-radius:10px" v-if="profile.lgi"></td>
                        </tr>

                        <tr>
                          <th>Acceptance Form</th>
                          <td><img class="img-fluid img-responsive" :src="profile.acceptance_form" style="height:60px; display: block; margin-left: auto; margin-right: auto; width: 50%; border-radius:10px" v-if="profile.acceptance_form"></td>
                        </tr>                    
                      </table>
                  </div>
              </div> 

              <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                      <tr>
                        <th>Blood Group</th>
                        <td>{{ profile.blood_group }}</td>
                      </tr>

                      <tr>
                        <th>Genotpe</th>
                        <td>{{ profile.genotype }}</td>
                      </tr>
                      
                      <tr>
                        <th>Asthmatic?</th>
                        <td>{{ profile.asthmatic }}</td>
                      </tr>

                      <tr>
                        <th>Eye Issues</th>
                        <td>{{ profile.eye }}</td>
                      </tr>

                      <tr v-if="profile.eye=='Yes'">
                        <th>Detail of Eye Issue</th>
                        <td>{{ profile.eye_issue }}</td>
                      </tr>

                      <tr>
                        <th>Disability</th>
                        <td>{{ profile.disability }}</td>
                      </tr>

                      <tr v-if="profile.eye=='Yes'">
                        <th>Details of Disability</th>
                        <td>{{ profile.disability_issue }}</td>
                      </tr> 
                      <tr>
                        <th>Any other Medical Issue</th>
                        <td>{{ profile.other_med }}</td>
                      </tr>             
                    </table>
                </div>
              </div>

              <div class="col-lg-6 col-md-6 col-sm-6 col-12" v-if="guardian!=null">
                <div class="table-responsive">
                    <table class="table table-bordered table-hover">
                      <tr>
                        <th>Name of Guardian</th>
                        <td>{{ guardian.title | capitalize }} {{ guardian.name }}</td>
                      </tr>

                      <tr>
                        <th>Guardian Email</th>
                        <td>{{ guardian.email }}</td>
                      </tr>
                      
                      <tr>
                        <th>Guardian Phone</th>
                        <td>{{ guardian.phone }}</td>
                      </tr>

                      <tr>
                        <th>Guardian Address</th>
                        <td>{{ guardian.address }}</td>
                      </tr>

                      <tr>
                        <th>Guardian Occupation</th>
                        <td>{{ guardian.occupation }}</td>
                      </tr>            
                    </table>
                </div>
              </div>
          </div>
        </div>
        <div class="card-footer">
          <button v-print="printObj" class="btn btn-info btn-sm float-right">Print Profile</button>
          <div id="loading" v-show="printLoading"></div>
        </div>
      </section>
    </div>
  </b-overlay>
</template>

<script>
  import axios from 'axios';
  export default {
    data(){
      return  { 
        guardian: '',     
        profile: '',
        is_busy: false,
        school:'',
        printLoading: true,
        printObj: {
          id: "printMe",
          extraCss: "https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css",
          extraJs: "https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js",
          extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
          beforeOpenCallback (vue) {
            vue.printLoading = true
          },
          openCallback (vue) {
            vue.printLoading = false
          },
          closeCallback (vue) {
          }
        },
      }
    },

    created(){
      this.roleHelper(['1', '2', '5']);
      this.loadPage();
    },

    methods: {
      loadPage(){
        if (this.is_busy) return;
        this.is_busy = true;
        
        this.id = this.$route.params.student_id;    
        axios.get('/students/' + this.id)
        .then((response) => {
            this.profile = response.data.data.profile;
            this.guardian = response.data.data.guardian;
            this.school = response.data.data.school;
        })
        .catch((err)=>{
            Swal.fire(
                "Error!",
                err.response.data.data.error,
                "error"
            );
            this.$router.push({path: '/administration/students' });
        })
        .finally(() => {
            this.is_busy = false;
        })
      },
    },
  }
</script>
